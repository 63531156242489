import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useLocation } from 'react-router-dom';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionData,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import scrollToVariant from '@PAGES/admin/edit-section/helpers/scrollToVariant';
import PageHeader from '@PAGES/admin/edit-section/components/PageHeader';
import VariantsStickyHeader from '@PAGES/admin/edit-section/components/VariantsStickyHeader';
import BaseTextStickyHeader from '@PAGES/admin/edit-section/components/BaseTextStickyHeader';
import Variant from '@PAGES/admin/edit-section/components/Variant';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import FloatingPublishButton from '@PAGES/admin/edit-section/components/FloatingPublishButton';
import Mandatory from '@PAGES/admin/edit-section/components/Mandatory';
import BaseText from '@PAGES/admin/edit-section/components/BaseText';

import AlertDialog from '@COMPONENTS/COMMON/dialog/dialog';
import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import { useUpdateVariant } from '@SERVICES';
import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        view, top, sectionData, sectionMeta,
    } = props;

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        updateVariant,
    ] = useUpdateVariant();

    const { actions: newVariantModalActions } = useNewVariantModal();

    const { hash } = useLocation();

    const { variants } = sectionData;

    const [isMandatoryOpen, setMandatoryOpen] = useState<boolean>(false);
    const [isBaseTextOpen, setBaseTextOpen] = useState<boolean>(true);
    const [openVariantsIds, setOpenVariantsIds] = useState<Set<number>>(
        new Set([]),
    );
    const [variantisDefault, setVariantsIsDefault] = useState<any>({});

    const sortedVariants = useMemo(() => variants.slice().sort((a, b) => {
        const isADefault = a.isDefault;
        const isBDefault = b.isDefault;

        if (isADefault && !isBDefault) return -1;
        if (!isADefault && isBDefault) return 1;

        return 0;
    }), [variants]);

    const updateIsDefaultTonggle = (variantId: number) => {
        const updateIds: number[] = [];
        const newDefaults = { ...variantisDefault };
        Object.keys(newDefaults).forEach((id) => {
            if (Number(id) === variantId) {
                newDefaults[variantId] = !newDefaults[variantId];
            } else if (newDefaults[Number(id)]) {
                newDefaults[Number(id)] = false;
                updateIds.push(Number(id));
            }
        });
        setVariantsIsDefault(newDefaults);
    };

    const updateVariants = (vId: number) => {
        const newV = variants.filter((v) => v.id !== vId && v.isDefault);

        newV.forEach((v) => {
            const o = {
                documentId: sectionData.document.id,
                chapterId: sectionData.chapter.id,
                sectionId: sectionData.id,
                id: v.id,
                isVisible: v.isVisible,
                body: v.body || '',
                isDefault: false,
                brands: v.brands,
                regions: v.regions,
                areas: v.areas,
                countries: v.countries,
                propertyTypes: v.propertyTypes,
            };

            updateVariant(o);
        });
    };

    const handleToggleDefault = (variantId: number, type = 'tonggle') => {
        switch (type) {
            case 'tonggle':
                updateIsDefaultTonggle(variantId);
                break;

            case 'update':
                updateVariants(variantId);
                break;

            default:
                updateIsDefaultTonggle(variantId);
                break;
        }
    };

    useEffect(() => {
        if (!variants.length) return;
        const defaults = variants.reduce((acc, { id, isDefault }) => {
            acc[id] = isDefault;
            return acc;
        }, {} as { [key: number]: boolean });

        setVariantsIsDefault(defaults);
    }, [variants]);

    useEffect(() => {
        if (hash) {
            const id = Number(hash.replace('#variant-', ''));

            if (!Number.isNaN(id)) {
                setOpenVariantsIds((set) => {
                    const newSet = new Set(set);

                    newSet.add(id);

                    return newSet;
                });
            }
        }
    }, [hash]);

    useEffect(() => {
        if (!variants.length) return;
        variants.forEach((variant) => {
            setOpenVariantsIds((set) => {
                const newSet = new Set(set);
                newSet.add(variant.id);
                return newSet;
            });
        });
    }, [variants]);

    useEffect(() => {
        const id = Number(hash.replace('#variant-', ''));

        let timer: any;

        if (!Number.isNaN(id)) {
            timer = setTimeout(() => {
                scrollToVariant(id);
            }, 150);
        }

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actionButton = (
        <>
            {/* <ActionButton
                buttonText="Mandatory Rules"
                variant="outlined"
                handleClick={() => setMandatoryOpen(true)}
                icon="mandatory"
                showbadge={sectionData.mandatoryConditions.length > 0}
            /> */}
            <FloatingPublishButton
                sectionData={sectionData}
                sectionMeta={sectionMeta}
                top={top}
                isBaseTextOpen={isBaseTextOpen}
            />
        </>
    );

    return (
        <div className={cx('content')}>
            <PageHeader
                sectionData={sectionData}
                sectionMeta={sectionMeta}
                actionButton={actionButton}
            />

            <div>
                {isMandatoryOpen && (
                    <AlertDialog
                        open={isMandatoryOpen}
                        handleClose={() => setMandatoryOpen(false)}
                        dialogContent={(
                            <Mandatory
                                sectionData={sectionData}
                                sectionMeta={sectionMeta}
                            />
                        )}
                        size="lg"
                    />
                )}
            </div>

            <div className={cx('accordion-wrap')}>
                <div className={cx('base-text-wrapper')}>
                    <BaseTextStickyHeader
                        sectionData={sectionData}
                        sectionMeta={sectionMeta}
                        top={top}
                        isBaseTextOpen={isBaseTextOpen}
                        setBaseTextOpen={setBaseTextOpen}
                    />

                    {isBaseTextOpen && (
                        <div>
                            <BaseText
                                sectionData={sectionData}
                                sectionMeta={sectionMeta}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* vender */}
            {/* <div className={cx('vendors-wrap')}>
                <span className={cx('vendors-text')}>Vendors</span>
                <SectionVendors
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div> */}

            <div className={cx('accordion-wrap')}>
                <VariantsStickyHeader
                    top={top}
                    sectionData={sectionData}
                    setOpenVariantsIds={setOpenVariantsIds}
                />
            </div>
            <div className="container">
                <div className={cx('variants-list')}>
                    <div className={cx('add-variant-button')}>
                        <AddButton
                            locked={!canEditDocument}
                            title="New variant"
                            fontSize={36}
                            onClick={() => {
                                newVariantModalActions.open({
                                    documentId: sectionData.document.id,
                                    chapterId: sectionData.chapter.id,
                                    sectionId: sectionData.id,
                                    canEditDocument,
                                });
                            }}
                        />
                    </div>

                    {sortedVariants.map((variant, index) => {
                        const key = variant.id;

                        const isVariantOpen = openVariantsIds.has(variant.id);
                        const isDefault = variantisDefault[key];

                        return (
                            <div
                                key={key}
                                className={cx('variant-item-wrapper', {
                                    'is-close': !isVariantOpen,
                                })}
                            >
                                <Variant
                                    variant={{ ...variant, variantIndex: `${variant.id}-${index}`, isDefault }}
                                    handleToggleDefault={handleToggleDefault}
                                    sectionData={sectionData}
                                    sectionMeta={sectionMeta}
                                    openVariantsIds={openVariantsIds}
                                    setOpenVariantsIds={setOpenVariantsIds}
                                    view={view}
                                />

                                {view === 'options' && (
                                    <div className={cx('delimiter')} />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

Content.propTypes = {
    view: PropTypes.oneOf(['options', 'sections']).isRequired,
    top: PropTypes.number.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    sectionData: AdminSingleSectionData;
    sectionMeta: AdminSingleSectionMeta;
};

export default Content;
