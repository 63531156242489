import React, { useMemo, useRef } from 'react';
import classNames from 'classnames/bind';
import SquareCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/SquareCheckbox';
import styles from './SubSection.module.scss';
import SubSectionStickHeader from '../SubSectionStickyHeader';

const cx: CX = classNames.bind(styles);

function SubSection(props: any) {
    const {
        heading,
        sectionData,
        selectedSubsectionIds,
        setSelectedSubsectionIds,
        isSubMenu = false,
        isPartial = false,
    } = props;

    const subMenuRef = useRef<Set<any>>(new Set());

    const subMenuData = useMemo(() => {
        subMenuRef.current.clear();
        sectionData.forEach((sec: any) => {
            sec.children?.forEach((child: any) => {
                if (selectedSubsectionIds.has(child.id)) {
                    subMenuRef.current.add(child);
                }
            });
        });
        return Array.from(subMenuRef.current);
    }, [sectionData, selectedSubsectionIds]);

    const isParentIdSelected = (
        sectionId: number,
        conditionSectionData: any[],
        conditionSelectedSubsectionIds: Set<number>,
    ) => {
        if (conditionSectionData.every((s) => s.parent_id !== sectionId)) return;

        const allChildren = conditionSectionData.flatMap(
            (section: any) => section.children,
        );
        const result = allChildren.length > 0
            ? allChildren.some((section: any) => conditionSelectedSubsectionIds.has(section.parent_id))
            : true;

        return (
            <div>
                {!isSubMenu && result && subMenuData?.length > 0 && (
                    <SubSection
                        heading="Sub Sub Sections"
                        sectionData={subMenuData}
                        selectedSubsectionIds={selectedSubsectionIds}
                        setSelectedSubsectionIds={setSelectedSubsectionIds}
                        isSubMenu
                        isPartial={isPartial}
                    />
                )}
            </div>
        );
    };

    const isSubSection = heading === 'Sub Sections';

    const toggleSelection = (id: number, isSelected: boolean) => {
        if (!isPartial) return;
        const updatedSet = new Set(selectedSubsectionIds);
        if (isSelected) {
            updatedSet.add(id);
        } else {
            updatedSet.delete(id);
        }
        setSelectedSubsectionIds(updatedSet);
    };

    const renderChildren = (children: any[], baseHeader: string) => {
        if (children.length === 0) {
            return null;
        }

        return children.map((child: any, index: number) => {
            const checked = selectedSubsectionIds.has(child.id);
            const header = `${baseHeader} ${index + 1}`;

            return (
                <div key={child.id} className={cx('grid-view')}>
                    <SquareCheckbox
                        header={header}
                        title={child.name}
                        checked={checked}
                        onChange={(val) => toggleSelection(child.id, val)}
                    />
                </div>
            );
        });
    };

    return (
        <div className={cx('sections')}>
            <div className={cx('sections-title-wrapper', 'container')}>
                <div className={cx('sections-title')}>{heading}</div>
            </div>

            <div className={cx('sections-lists-wrapper')}>
                {sectionData.map((sec: any, secIndex: number) => {
                    if (!sec.children.length) {
                        return null;
                    }
                    return (
                        <div key={sec.id}>
                            <SubSectionStickHeader
                                section={sec}
                                index={secIndex}
                                selectedSubsectionIds={selectedSubsectionIds}
                                setSelectedSubsectionIds={setSelectedSubsectionIds}
                                isSubSection={isSubSection}
                                isPartial={isPartial}
                            />
                            <div className={cx('grid-wrapper')}>
                                {renderChildren(
                                    sec.children || [],
                                    heading.slice(0, -1),
                                )}
                            </div>
                            <div>
                                {isParentIdSelected(sec.id, subMenuData, selectedSubsectionIds)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SubSection;
