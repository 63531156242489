import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ContentChapter, Section } from '@INTERFACES/api/content';

import ChapterStickyHeader from '@PAGES/manager/select-content/components/ChapterStickyHeader';
import GridView from '@PAGES/manager/select-content/components/GridView';
import onlyMandatorySections from '@PAGES/manager/select-content/helpers/onlyMandatorySections';

import styles from './Sections.module.scss';
import SubSection from '../SubSection';

const cx: CX = classnames.bind(styles);

function Sections(props: Props) {
    const {
        chaptersData,
        selectedChaptersIds,
        selectedSectionsIds,
        selectedVariantsIds,
        setSelectedSectionsIds,
        setSelectedVariantsIds,
        isPartial,
        isOnlyRequiredSections,
    } = props;

    let sectionsData = chaptersData.filter((chapter) => selectedChaptersIds.has(chapter.id));

    if (isOnlyRequiredSections) {
        if (isPartial) {
            sectionsData = sectionsData.map((chapter: ContentChapter) => ({
                id: chapter.id,
                name: chapter.name,
                order: chapter.order,
                hasBody: chapter.hasBody,
                isMandatory: chapter.isMandatory,
                tags: chapter.tags,
                sections: [],
            }));
        } else {
            sectionsData = onlyMandatorySections(sectionsData);
        }
    }

    const onSelectAll = (sections: Section[]) => {
        const sectionsIds = sections.map((section) => section.id);

        const selectedSectionsArray = Array.from(selectedSectionsIds);

        const sectionsSet = new Set([
            ...selectedSectionsArray,
            ...sectionsIds,
        ]);

        const variantsSet = new Set(selectedVariantsIds);

        sections.forEach((section) => {
            const { variants } = section;

            const availableVariantsIds = variants.map((v) => v.id);

            const hasSelected = availableVariantsIds.some((id) => variantsSet.has(id));

            if (!hasSelected) {
                const defaultVariant = variants[0];

                if (defaultVariant) {
                    variantsSet.add(defaultVariant.id);
                }
            }
        });

        return { sectionsSet, variantsSet };
    };

    return (
        <div className={cx('sections')}>
            <div className={cx('sections-title-wrapper', 'container')}>
                <div className={cx('sections-title')}>
                    Sections
                </div>
            </div>

            <div className={cx('sections-lists-wrapper')}>
                {
                    sectionsData.map((chapter) => {
                        const { sections } = chapter;
                        const filteredSections = sections.filter((section) => selectedSectionsIds.has(section.id));

                        const chapterKey = chapter.id;
                        let chSelectedSectionsIds = selectedSectionsIds;
                        let chSelectedVariantsIds = selectedSectionsIds;

                        const foundChapterIndex = chaptersData.findIndex((c) => c.id === chapter.id);
                        if (!isPartial) {
                            const newIds = onSelectAll(sections);
                            chSelectedSectionsIds = newIds.sectionsSet;
                            chSelectedVariantsIds = newIds.variantsSet;
                        }

                        return (
                            <div
                                key={chapterKey}
                                className={cx('sections-list', {
                                    'remove-margin': filteredSections.length,
                                })}
                            >
                                <ChapterStickyHeader
                                    chapter={chapter}
                                    index={foundChapterIndex}
                                    selectedSectionsIds={chSelectedSectionsIds}
                                    selectedVariantsIds={chSelectedVariantsIds}
                                    setSelectedSectionsIds={setSelectedSectionsIds}
                                    setSelectedVariantsIds={setSelectedVariantsIds}
                                    isPartial={isPartial}
                                />

                                {
                                    sections.length
                                        ? (
                                            <>
                                                <GridView
                                                    sections={sections}
                                                    selectedSectionsIds={selectedSectionsIds}
                                                    selectedVariantsIds={selectedVariantsIds}
                                                    setSelectedSectionsIds={setSelectedSectionsIds}
                                                    setSelectedVariantsIds={setSelectedVariantsIds}
                                                    isPartial={isPartial}
                                                    isChapterMandatory={chapter.isMandatory}
                                                />
                                                {filteredSections.length > 0 && (
                                                    <SubSection
                                                        heading="Sub Sections"
                                                        sectionData={filteredSections}
                                                        selectedSubsectionIds={selectedSectionsIds}
                                                        setSelectedSubsectionIds={setSelectedSectionsIds}
                                                        isPartial={isPartial}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    chapter.hasBody
                                                    && (
                                                        <div className={cx('chapter-has-body', 'container')}>
                                                            Chapter has introduction text only
                                                        </div>
                                                    )
                                                }
                                                <div className={cx('sections-not-found', 'container')}>
                                                    {
                                                        isOnlyRequiredSections
                                                            ? 'Required sections not found!'
                                                            : 'Sections not found!'
                                                    }
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

Sections.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
    isPartial: PropTypes.bool.isRequired,
    isOnlyRequiredSections: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Sections.propTypes> & { chaptersData: ContentChapter[] };

export default Sections;
