export const EditorStyleContent = `
    .tox .tox-menubar {
      background-color: #31697a !important;
    }
    .tox .tox-mbtn {
      color: #ffffff !important;
    }
    .tox:not(.tox-tinymce-inline) .tox-editor-header {
      background-color: #31697a !important;
    }
    .tox .tox-toolbar-overlord > .tox-toolbar__primary {
      background-color: #A7B5BE !important;
    }
  `;
