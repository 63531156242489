import React, { useEffect, useState } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import {
    AdminSingleChapterData,
    AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import { useUpdateChapter } from '@SERVICES';

import ChapterName from '@PAGES/admin/edit-chapter/components/ChapterName';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import { Typography } from '@mui/material';
import styles from './PageHeader.module.scss';

const cx: CX = classnames.bind(styles);

function PageHeader(props: Props) {
    const { chapterData, chapterMeta, actionButton } = props;

    const [updateChapter, {
        isSuccess, isError, error,
    }] = useUpdateChapter();

    const [isMandatory, setMandatory] = useState<boolean>(
        chapterData.isMandatory,
    );

    useEffect(() => {
        if (isSuccess) {
            notify.info('Chapter has been updated', {
                toastId: 'update-chapter-params',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-chapter-params-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-chapter-params-error',
                });
            }
        }
    }, [isError, error]);

    const handleUpdateChapter = (isMandatoryVal: boolean) => {
        updateChapter({
            id: chapterData.id,
            documentId: chapterData.document.id,
            name: chapterData.name,
            body: chapterData.introductionText.body,
            isMandatory: isMandatoryVal,
            image: '',
        });
    };

    return (
        <div className={cx('page-header')}>
            <div className={cx('page-wrap')}>
                <ChapterName
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                    actionButton={actionButton}
                    // bodyContent={(
                    //     <div className={cx('chapter-options')}>
                    //         <Typography>Options:</Typography>
                    //         <div className={cx('options')}>
                    //             <div className={cx('option')}>
                    //                 <ActionButton
                    //                     buttonText="Mandatory"
                    //                     variant="outlined"
                    //                     handleClick={() => {
                    //                         setMandatory((val: boolean) => {
                    //                             const newValue = !val;
                    //                             handleUpdateChapter(newValue);
                    //                             return newValue;
                    //                         });
                    //                     }}
                    //                     isActive={isMandatory}
                    //                 />
                    //             </div>
                    //         </div>
                    //     </div>
                    // )}
                />

            </div>
        </div>
    );
}

PageHeader.defaultProps = {
    actionButton: null,
};

type Props = {
    chapterData: AdminSingleChapterData;
    chapterMeta: AdminSingleChapterMeta;
    actionButton?: React.ReactNode;
};

export default PageHeader;
