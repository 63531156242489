import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ContentChapter } from '@INTERFACES/api/content';

import onlyNotMandatorySections from '@PAGES/manager/select-content/helpers/onlyNotMandatorySections';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './ChapterStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterStickyHeader(props: Props) {
    const {
        chapter,
        index,
        selectedSectionsIds,
        selectedVariantsIds,
        setSelectedSectionsIds,
        setSelectedVariantsIds,
        isPartial,
    } = props;

    const { sections } = chapter;

    const onSelectAll = () => {
        const sectionsIds = sections.map((section) => section.id);

        const selectedSectionsArray = Array.from(selectedSectionsIds);

        const sectionsSet = new Set([
            ...selectedSectionsArray,
            ...sectionsIds,
        ]);

        const variantsSet = new Set(selectedVariantsIds);

        sections.forEach((section) => {
            const { variants } = section;

            const availableVariantsIds = variants.map((v) => v.id);

            const hasSelected = availableVariantsIds.some((id) => variantsSet.has(id));

            if (!hasSelected) {
                const defaultVariant = variants[0];

                if (defaultVariant) {
                    variantsSet.add(defaultVariant.id);
                }
            }
        });

        setSelectedSectionsIds(sectionsSet);
        setSelectedVariantsIds(variantsSet);
    };

    return (
        <StickyHeader top={80}>
            <div
                className={cx('chapter-sticky-header', 'container')}
            >
                <div className={cx('chapter-title')}>
                    {`Chapter ${index + 1} - ${chapter.name}`}
                </div>
                {isPartial && (
                    <div className={cx('selectors')}>
                        <PairToggleButtons
                            titleOn="Select all"
                            titleOff="Deselect all"
                            onClickOn={onSelectAll}
                            onClickOff={() => {
                                const sectionsIds = onlyNotMandatorySections(
                                    sections,
                                    isPartial,
                                    chapter.isMandatory,
                                );

                                const sectionsSet = new Set(selectedSectionsIds);
                                const variantsSet = new Set(selectedVariantsIds);

                                sectionsIds.forEach((id) => {
                                    if (sectionsSet.has(id)) {
                                        sectionsSet.delete(id);
                                    }
                                });

                                const availableVariantsIds = sections
                                    .filter((s) => sectionsIds.includes(s.id))
                                    .reduce((acc: number[], section) => {
                                        const { variants } = section;

                                        return acc.concat(variants.map((v) => v.id));
                                    }, []);

                                availableVariantsIds.forEach((id) => {
                                    variantsSet.delete(id);
                                });

                                setSelectedSectionsIds(sectionsSet);
                                setSelectedVariantsIds(variantsSet);
                            }}
                        />
                    </div>
                )}
            </div>
        </StickyHeader>
    );
}

ChapterStickyHeader.propTypes = {
    index: PropTypes.number.isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
    isPartial: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ChapterStickyHeader.propTypes> & {
    chapter: ContentChapter,
};

export default ChapterStickyHeader;
